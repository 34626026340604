import React, { useEffect, useState } from "react";
import { getSentNotification } from "./NotificationApi";

const Notification = () => {

  const [filterData, setFilterData] = useState([]);
  const [notificationData, setNotificationData] = useState([]);
  const [currentTab, setCurrentTab] = useState("1");
  const [selectedTab, setSelectedTab] = useState();
  const userId = localStorage.getItem("id");
  const [tabContent, setTabContent] = useState("All");

  const notificationTabs = [
    {
      id: "1",
      count: notificationData.length,
      title: "All",
    },
    {
      id: "2",
      count: notificationData.filter((data) => data.isRead === 1).length,
      title: "Read",
    },
    {
      id: "3",
      count: notificationData.filter((data) => data.isRead === 0).length,
      title: "Unread",
    },
  ];

  const getNotification = () => {
    getSentNotification(userId).then((response) => {
      if (!response) {
        console.log("apiError");
        return;
      }

      if (response?.data?.status && response?.data?.statusCode === 200) {
        setNotificationData(response?.data?.response);
        setFilterData(response?.data?.response);
      }
    });
  };

  const handleTabClick = (e) => {
    setCurrentTab(e?.target?.id);
    const tab = e?.target?.id;
    tabClick(tab);
  };

  const tabClick = async (tab) => {
    setSelectedTab(tab);
    if (tab === "1") {
      setFilterData(notificationData);
      setTabContent("All");
    } else if (tab === "2") {
      const data = notificationData?.filter((data) => data.isRead === 1);
      setTabContent("Read");
      setFilterData(data);
    } else {
      setTabContent("Unread");
      const data = notificationData?.filter((data) => data.isRead === 0);
      setFilterData(data);
    }
  };

  const formatDateTime = (dateString) => {
    const date = new Date(dateString);

    // Map for abbreviated months
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const day = String(date.getDate()).padStart(2, "0");
    const month = months[date.getMonth()];
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${day}-${month}-${year} ${hours}:${minutes}`;
  };

  useEffect(() => {
    getNotification();

    // Set interval to call it every 5 minutes (300,000 ms)
    const intervalId = setInterval(getNotification, 300000);

    // Clear interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      <div className="bg-[#F4F4FA] p-4 md:p-8 h-[calc(100%_-_8%)]">
        <div className="flex justify-between mb-4 px-4">
          <div className="flex">
            <h2 className="text-[20px] text-[#101942] font-bold">
              Notification
            </h2>
            <p className="bg-gradient-to-r from-[#FF9200] to-[#F6BF52] rounded-[23px] text-[16px]  text-white py-[2px] px-[15px] ml-3">
              {notificationData.length}
            </p>
          </div>
        </div>
        <div className="bg-[#fff] rounded-[30px] h-[calc(100%_-_8%)]">
          <div className="flex px-8 pt-4 flex-wrap">
            {notificationTabs?.map((tabs, i) => (
              <button
                // disabled={tabs.id === 4}
                key={i}
                id={tabs.id}
                onClick={handleTabClick}
                className={`${
                  currentTab === tabs.id
                    ? "gradient-96 text-[#fff]"
                    : " bg-[#E3E3EF] text-[#10194280]"
                }  px-8 py-[7px] rounded-[25px] mx-[10px] my-2 text-[14px] lg:w-[30%] w-[100%]`}
              >
                {tabs.title} &nbsp; ({tabs.count})
                {/* {localStorage.setItem("intTabId", selectedTab)} */}
              </button>
            ))}
          </div>
          <div className="lg:p-[15px] md:p-[30px] p-[15px] flex flex-row flex-wrap overflow-y-scroll md:h-[calc(100vh-33vh)] h-[calc(100vh-35vh)] h=[100vh] no-scrollbar p">
            <>
              {filterData?.length === 0 ? (
                <div className="flex flex-col justify-center w-full items-center">
                  <img
                    src="../Images/screen1.svg"
                    alt="Screening 1"
                    className="lg:w-[30%] lg:mb-[30px]"
                  />
                  <h1 className="text-lg font-semibold">
                    {"No Data Found!"}
                  </h1>
                </div>
              ) : (
                <>
                  {filterData?.map((data) => (
                    <div className="rounded-[12px] bg-[#fff] xl:w-[90%] lg:w-[90%] w-[100%] lg:mx-[20px] my-[15px] shadow-[0px_0px_20px_#48456C21] h-fit">
                      <div className="flex flex-row items-center mx-[20px] mb-[20px] pt-[20px]">
                        <img
                          src={
                            data.isRead === 0
                              ? "../Images/red-dot.png"
                              : "../Images/green-tick.png"
                          }
                          alt=""
                          className={`${
                            data.isRead === 1 ? "border-[#00cc00]" : ""
                          } rounded-[100%] border-2 w-[40px] h-[40px]`}
                        />
                        <div className="flex flex-row justify-between w-full">
                          <div className="flex flex-col text-left pl-[15px]">
                            <p className="mb-[5px] font-medium">{data.title}</p>
                            <p className="text-[#848BAA] text-[13px]">
                              {data.body}
                            </p>
                          </div>
                          <p className="text-[#A9A9A9] text-[12px] w-[180px] text-right">
                            {formatDateTime(data.sendDate)}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              )}
            </>
          </div>
        </div>
      </div>
    </>
  );
};

export default Notification;
