import axios from "axios";
import { baseURLCommApi } from "../../../utils/http/http-common";
import { apiEndPoints } from "../../../utils/http";

export const getSentNotification = async (userId) => {
  try {
    const response = await axios.get(
      `${baseURLCommApi}${apiEndPoints.GetSentNotification_CommApi}/${userId}`
    );
    return response;
  } catch (error) {
    return error.config.data;
  }
};

