import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { userLogin } from "../../../../redux/slices/auth/authSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Loader from "../../../../utils/loader/Loader";

const CustomLogin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(true);

  const [searchParams] = useSearchParams();
  let UserName = searchParams.get("us");
  let Password = searchParams.get("pd").replace(" ", "+");
  let UserTypeId = searchParams.get("ut");

  const userData = {
    userName: UserName,
    password: Password,
    userTypeId: UserTypeId,
  };

  const authenticate = async () => {
    const response = await dispatch(userLogin(userData)).unwrap();
    console.log(response);
    if (response.message === "Success") {
      const userId = response.userId;
      const token = response.token;
      localStorage.setItem("token", token);
      localStorage.setItem("id", userId);
      localStorage.setItem("l_id", 1);
      localStorage.setItem("userTypeId", UserTypeId);
      localStorage.setItem("user", JSON.stringify(response.userResponse));
      setTimeout(() => {
        setLoader(false);
        navigate("/user/dashboard");
      }, 1000);
    }
  };

  useEffect(() => {
    //alert("UserName "+ uName + " Password "+pwd+" UserType " +utype);
    authenticate();
  }, []);

  return (
    <>
      {loader === true ? (
        <>
          <div
            className="fixed inset-0 flex justify-center items-center bg-white bg-opacity-80"
            style={{ height: "100vh", width: "100vw" }}>
            <Loader type="spinningBubbles" color="#2e376c" />
          </div>
        </>
      ) : (
        <>
          <div></div>
        </>
      )}
    </>
  );
  
};

export default CustomLogin;
