import axios from "axios";

export const baseURL = "https://api-pi.projectinclusion.in/api";
 //export const baseURL = "https://staging-api-pi.projectinclusion.in/api";
// 
export const baseURLSchoolApi = "https://api-school.projectinclusion.in/api";

export const baseURLCommApi = "https://api-comm.projectinclusion.in/api";

export const http = axios.create({
  baseURL,
});

export const httpSchool = axios.create({
  baseURL : baseURLSchoolApi,
});

export const httpComm = axios.create({
  baseURL : baseURLCommApi,
});
